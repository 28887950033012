<div *ngIf="loadingContract$ |async" class="loading page" t7e="contract-loading">
    A szerződés töltődik
</div>

<div *ngIf="(loadContractError$ |async)" class="load-error">
    {{ loadContractError$ |async }}
</div>

<div *ngIf="!isCrew" class="warning no-crew-warning">
    <app-page-warning [cssClass]="'warning'" [title]="t7eNoCrewWarning"></app-page-warning>
</div>
<div *ngIf="!this.canEditForm" class="warning not-editable-warning">
    <app-page-warning [cssClass]="'info'" [title]="t7eNotEditableWarning"></app-page-warning>
</div>

<div class="edit-contract" *ngIf="isCrew"
    kendoPopoverContainer filter=".has-history" [popover]="popHistory" [showOn]="'click'">
    <div class="load-error" *ngIf="loadContractError$ | async">
        {{ loadContractError$ | async }}
    </div>
    <button (click)="test()" *ngIf="debug" type="button">TEST</button>

    <div class="saving-contract" *ngIf="savingContract$ | async" t7e="saving-contract">A start form mentése folyamatban</div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <section class="debug" *ngIf="debug">
            form.dirty: {{ form.dirty }},
            form.valid: {{ form.valid }},
            canEditForm: {{ canEditForm }},
            debug: {{ debug }},
            error controls: {{ getFormErrors() | json }}
            contractUser: {{ contractUser | json }}
            contractStatus: {{ contractStatus | json }}
            selectedContract: {{ contract$ | async | json }}
            usid: {{ this.contractUser?.usid || this.user$?.value?.usid || 'this.userSvc.loggedinUser?.usid' }}
        </section>

        <app-status *ngIf="showContractStatus || contract?.savecomment" 
            [statusName]="getContractStatusName((contract$ | async)?.constatus)" 
            [statusClass]="getContractStatusClass(contract$ | async)"
            [saveComment]="contract?.savecomment || null" [statusOf]="statusOf || null"></app-status>

        <section class="userdata mb-1" *ngIf="!isContractUserMyself">
            <app-user-card [user]="contractUser"></app-user-card>
        </section>

        <app-section-title [title]="t7eSectionDeptAndRole"></app-section-title>
        <section class="department">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label t7e="dept">Részleg</mat-label>
                <mat-select matNativeControl formControlName="department">
                    <mat-option *ngFor="let option of departments$ | async" [value]="option">
                        {{ option.depname }}
                    </mat-option>
                </mat-select>
                <mat-hint t7e="dept-hint">
                    Ha nem találod a részleged, akkor az Egyéb opciót válaszd
                </mat-hint>
                <mat-error *ngIf="form?.hasError('required', 'department')" t7e="required-field-error">
                    Kötelező kitölteni
                </mat-error>
            </mat-form-field>
            <mat-form-field class="form-field" appearance="outline">
                <mat-label t7e="role">Pozíció</mat-label>
                <input matNativeControl formControlName="position">
                <mat-hint t7e="role-hint">A részlegen belüli feladatod. Pl: fővilágosító</mat-hint>
                <mat-error *ngIf="form?.hasError('required', 'position')" t7e="required-field-error">
                    Kötelező kitölteni
                </mat-error>
            </mat-form-field>
            <div *ngIf="isPositionDuplicate$ | async" t7e="duplicate-position-error" class="duplicate-position-error">
                Nem lehet két start formban ugyanaz a pozíció. Ha ugyanaz a pozíciód, akkor is írj bele valami megkülönböztetést,
                amiben különbözik a többitől: pl ekho, cégnév, napidíj összege, stb.
            </div>
        </section>

        <div style="width: 100vw" *ngIf="debug">
            {{ prodDefaults | json }}
        </div>

        <div style="width: 100vw; margin-top:3rem" *ngIf="debug">
            {{ deptDefaults$ | async | json }}
        </div>

        <!-- DÍJAK -->
        <ng-container *ngIf="isMoreThanCrew || ALLOW_CREW_MEMBER_EDIT_FEES">
        <app-section-title [title]="t7eFees" *ngIf="isMoreThanCrew || !hasInvoiceContact"></app-section-title>
        <section class="all-fees" *ngIf="isMoreThanCrew || !hasInvoiceContact">
            <div class="fees currency-field" [class.crew]="!isMoreThanDeptHead">
                <div class="grid-header" t7e="value-in-start-form">Start formban megadott érték</div>
                <div class="grid-header grid-header-history" t7e="history" t7e-title>History</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="inherited">Örökölt</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="dept-default">Department default</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="prod-default">Production default</div>

                <mat-form-field class="form-field" appearance="outline">
                    <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_currency')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                    <mat-label t7e="currency">Elszámolás pénzneme</mat-label>
                    <input type="text" matInput formControlName="f_saved_currency" [matAutocomplete]="f_saved_currency">
                    <mat-autocomplete #f_saved_currency="matAutocomplete">
                        <mat-option *ngFor="let option of allCurrencies$ | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint><span t7e="if-not">Ha nem</span> {{prodDefaults?.currency}}</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_currency', true)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead">
                    <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_currency')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                    {{ (contract$ | async)?.f_currency }} 
                </div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead">{{prodDefaults?.currency}}</div>
            </div>

            <div class="fees fees1" [class.crew]="!isMoreThanDeptHead">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label t7e="preprod-fee-label">Napidíj előkészítés alatt</mat-label>
                    <input matNativeControl #prepRate formControlName="preprodrate" type="number" min="0" step="10000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-hint t7e="preprod-fee-hint">Csak akkor kell kitölteni, ha a forgatás előtt más napidíj jár</mat-hint>
                    <mat-error *ngIf="form?.hasError('pattern', 'preprodrate')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error *ngIf="form?.hasError('min', 'preprodrate') || form?.hasError('max', 'preprodrate')">
                        {{ getMinMaxErrorMsg(prepRate) }}
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'preprodrate', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ (flexPropMap$ | async)?.get('preprodRateWithLetters')?.propName }}</mat-label>
                    <input matNativeControl formControlName="f_preprodRateWithLetters">
                    <mat-hint>{{ (flexPropMap$ | async)?.get('preprodRateWithLetters')?.propDesc }}</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_preprodRateWithLetters', true)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ (flexPropMap$ | async)?.get('preprodRateEng')?.propName }}</mat-label>
                    <input matNativeControl formControlName="f_preprodRateEng">
                    <mat-hint>{{ (flexPropMap$ | async)?.get('preprodRateEng')?.propDesc }}</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_preprodRateEng', true)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label t7e="daily-fee-label">Napidíj</mat-label>
                    <input matNativeControl #dailyRate formControlName="salary" type="number" min="0" step="10000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-hint t7e="daily-fee-hint">Normál napidíj</mat-hint>
                    <mat-error *ngIf="form?.hasError('pattern', 'salary')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error *ngIf="form?.hasError('min', 'salary') || form?.hasError('max', 'salary')">
                        {{ getMinMaxErrorMsg(dailyRate) }}
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'dailyrate', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>


                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ (flexPropMap$ | async)?.get('dailyfeewithletters')?.propName }}</mat-label>
                    <input matNativeControl formControlName="f_dailyfeewithletters">
                    <mat-hint>{{ (flexPropMap$ | async)?.get('dailyfeewithletters')?.propDesc }}</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_dailyfeewithletters', true)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{ (flexPropMap$ | async)?.get('dailyRateEng')?.propName }}</mat-label>
                    <input matNativeControl formControlName="f_dailyRateEng">
                    <mat-hint>{{ (flexPropMap$ | async)?.get('dailyRateEng')?.propDesc }}</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_dailyRateEng', true)" type="button" class="has-history"
                    tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>

                <mat-form-field class="form-field" appearance="outline">
                    <mat-label t7e="contracted-fee-label">Szerződéses díj</mat-label>
                    <input matNativeControl formControlName="contractfee" type="number">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-hint t7e="contracted-fee-hint">Csak egyösszegű szerződés esetén</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'contractfee', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>

                <ng-container *ngIf="isProdMgr">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label [innerText]="(flexPropMap$ | async)?.get('numPlannedDays')?.propName">Összes tervezett nap</mat-label>
                        <input matNativeControl formControlName="f_numPlannedDays" type="number" min="0">
                        <span matSuffix><span t7e="day-suffix"></span></span>
                        <mat-hint>{{ (flexPropMap$ | async)?.get('numPlannedDays')?.propDesc }}</mat-hint>
                    </mat-form-field>
                    <button mat-icon-button (click)="showHistory($event, 'f_numPlannedDays', true)" type="button" class="has-history"
                        tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                    <div class="effective-value" *ngIf="isMoreThanDeptHead"></div>
                    <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                    <div class="prod-default" *ngIf="isMoreThanDeptHead"></div>
                </ng-container>

                <ng-container *ngIf="ALLOW_PERDIEM || isMoreThanDeptHead">
                    <mat-form-field class="form-field" appearance="outline">
                        <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_sleepoverrate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                        <mat-label t7e="perdiem-label">Per Diem díj</mat-label>
                        <input matNativeControl #pd formControlName="f_saved_sleepoverrate" type="number" min="0" step="1000">
                        <span matSuffix>&nbsp;{{ currency }}</span>
                        <mat-hint *ngIf="isMoreThanDeptHead"><span t7e="perdiem-hint">Az extra összeg, amely ottalvós napokon jár. </span> <span t7e="only-if-not">Csak ha nem</span>
                            {{ (deptDefaults$ | async)?.f_sleepoverrate || prodDefaults?.sleepoverrate }}
                        </mat-hint>
                        <mat-error *ngIf="form?.hasError('pattern', 'f_saved_sleepoverrate')" t7e="invalid-number-error">
                            Érvénytelen szám
                        </mat-error>
                        <mat-error *ngIf="form?.hasError('min', 'f_saved_sleepoverrate') || form?.hasError('max', 'f_saved_sleepoverrate')">
                            {{ getMinMaxErrorMsg(pd) }}
                        </mat-error>
                    </mat-form-field>
                    <button mat-icon-button (click)="showHistory($event, 'f_sleepoverrate', true)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                    <div class="effective-value" *ngIf="isMoreThanDeptHead">
                        <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_sleepoverrate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                        {{ (contract$ | async)?.f_sleepoverrate }}&nbsp;{{ currency }}
                    </div>
                    <div class="dept-default" *ngIf="isMoreThanDeptHead">
                        {{(deptDefaults$ | async)?.f_sleepoverrate || '-'}}
                        {{ prodDefaultCurrency }}
                    </div>
                    <div class="prod-default" *ngIf="isMoreThanDeptHead">
                        {{prodDefaults?.sleepoverrate}}
                        {{ prodDefaultCurrency }}
                    </div>
                </ng-container>

            </div>

            <!-- OT -->
            <app-section-title [title]="t7eSectionOt"></app-section-title>
            <div class="fees fees-ot" [class.crew]="!isMoreThanDeptHead">
                <div class="grid-header" t7e="value-in-start-form">Start formban megadott érték</div>
                <div class="grid-header grid-header-history" t7e="history" t7e-title>History</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="inherited">Örökölt</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="dept-default">Department default</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="prod-default">Production default</div>
                <!--NEM KELL START FORMBAN WORKHOURS, MERT A TS-BEN KÖTELEZŐ MEZŐ
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label t7e="workhours-label">Munkanap hossza</mat-label>
                    <input matNativeControl #workHours formControlName="f_saved_workhours" type="number" min="0" step="1">
                    <span matSuffix t7e="hour-suffix"> óra</span>
                    <mat-hint><span t7e="only-if-not">Csak ha nem</span> {{ prodDefaults?.workhours }}</mat-hint>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_saved_workhours')" t7e="not-a-number-error">
                        Nem számot adtál meg
                    </mat-error>
                    <mat-error *ngIf="form?.hasError('min', 'f_saved_workhours') || form?.hasError('max', 'f_saved_workhours')">
                        {{ getMinMaxErrorMsg(workHours) }}
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_workhours', true)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead">
                    {{prodDefaults?.workhours}}
                    <span t7e="hour-suffix"> óra</span>
                </div> -->

                <ng-container *ngIf="isMoreThanDeptHead">
                    <mat-form-field class="form-field" appearance="outline">
                        <div class="will-be-effective-value" *ngIf="!isFieldEmpty('f_saved_graceperiod')"
                            [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                        <mat-label>Grace Period</mat-label>
                        <input matNativeControl #gracePeriod formControlName="f_saved_graceperiod" type="number" min="0"
                            step="15">
                        <span matSuffix t7e="minute-suffix"> perc</span>
                        <mat-hint><span t7e="only-if-not">Csak ha nem</span> {{ prodDefaults?.graceperiod }}<span t7e="minute-suffix">
                                perc</span></mat-hint>
                        <mat-error
                            *ngIf="form?.hasError('min', 'f_saved_graceperiod')">
                            {{ getMinMaxErrorMsg(gracePeriod) }}
                        </mat-error>
                        <mat-error *ngIf="form?.hasError('pattern', 'f_saved_graceperiod')" t7e="invalid-number-error">
                            Érvénytelen szám
                        </mat-error>
                    </mat-form-field>
                    <button mat-icon-button (click)="showHistory($event, 'f_graceperiod', true)" type="button" class="has-history"
                        tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                    <div class="effective-value">
                        <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_graceperiod')"
                            [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                        {{ (contract$ | async)?.f_graceperiod }}<span t7e="minute-suffix"> perc</span>
                    </div>
                    <div class="dept-default">{{(deptDefaults$ | async)?.f_graceperiod || '-'}}<span
                            t7e="minute-suffix"> perc</span></div>
                    <div class="prod-default">{{prodDefaults?.graceperiod}}<span t7e="minute-suffix">
                        perc</span></div>
                </ng-container>

                <mat-form-field class="form-field otrates" appearance="outline">
                    <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_otrate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                    <mat-label t7e="ot-rate-label">OT díj</mat-label>
                    <input matNativeControl formControlName="f_saved_otrate">
                    <span matSuffix>&nbsp;
                        <ng-container *ngIf="!ALLOW_OT_PERCENT">
                            {{ currency }}
                        </ng-container>
                        <ng-container *ngIf="ALLOW_OT_PERCENT">
                            <button type="button" (click)="toggleDimension()" class="suffix-button">
                                <span *ngIf="otDimension===1"><mat-icon>check</mat-icon></span>
                                %
                            </button>
                            <button type="button" (click)="toggleDimension()" class="suffix-button">
                                <span *ngIf="otDimension!==1"><mat-icon>check</mat-icon></span>
                                {{ currency ?? prodDefaults?.currency }}
                            </button>
                        </ng-container>
                    </span>
                    <mat-hint>
                        <span *ngIf="isMoreThanDeptHead; else otHintForCrew">
                            <span t7e="only-if-not">Csak ha nem</span> {{ deptDefaultOtRate || prodDefaultOtRate }}&nbsp;{{ prodDefaultCurrency }}                            
                        </span>
                        <ng-template #otHintForCrew>
                            <span *ngIf="SHOW_GROWING_OT_RATE" t7e="ot-rate-hint">"1000" vagy "1000 / 2000 / 3000"</span>
                        </ng-template>
                    </mat-hint>
                    <mat-error *ngIf="SHOW_GROWING_OT_RATE && form?.hasError('otRate', 'f_saved_otrate')" t7e="ot-rate-error-growing-ot">
                        A helyes formátum: "1000" vagy "1000 / 2000 / 3000"
                    </mat-error>
                    <mat-error *ngIf="!SHOW_GROWING_OT_RATE && form?.hasError('otRate', 'f_saved_otrate')" t7e="ot-rate-error">
                        Helytelen formátum
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_otrate', true)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead">
                    <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_otrate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                    {{ (contract$ | async)?.f_otrate | otRate }}&nbsp;{{ currency }}
                </div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead">{{deptDefaultOtRate|| '-'}} {{ prodDefaultCurrency }}</div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead">{{prodDefaultOtRate}} {{ prodDefaultCurrency }}</div>

                <ng-container *ngIf="ALLOW_CREW_MEMBER_EDIT_OT_STEP || isMoreThanDeptHead">
                    <mat-form-field class="form-field" appearance="outline">
                        <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_overtimestep')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                        <mat-label t7e="ot-step-label">Túlóra lépcső</mat-label>
                        <input matNativeControl #otStep formControlName="f_saved_overtimestep" type="number" min="0.01" max="1"
                            step="0.25">
                        <span matSuffix t7e="hour-suffix"> óra</span>
                        <mat-hint><span t7e="only-if-not">Csak ha nem</span> {{ prodDefaults?.overtimestep }}<span t7e="hour-suffix"> óra</span></mat-hint>
                        <mat-error *ngIf="form?.hasError('min', 'f_saved_overtimestep') || form?.hasError('max', 'f_saved_overtimestep')">
                            {{ getMinMaxErrorMsg(otStep) }}
                        </mat-error>
                        <mat-error *ngIf="form?.hasError('pattern', 'f_saved_overtimestep')" t7e="invalid-number-error">
                            Érvénytelen szám
                        </mat-error>
                    </mat-form-field>
                    <button mat-icon-button (click)="showHistory($event, 'f_overtimestep', true)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                    <div class="effective-value" *ngIf="isMoreThanDeptHead">
                        <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_overtimestep')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                        {{ (contract$ | async)?.f_overtimestep }}<span t7e="hour-suffix"> óra</span>
                    </div>
                    <div class="dept-default" *ngIf="isMoreThanDeptHead">{{(deptDefaults$ | async)?.f_overtimestep || '-'}}<span t7e="hour-suffix"> óra</span></div>
                    <div class="prod-default" *ngIf="isMoreThanDeptHead">{{prodDefaults?.overtimestep}}<span t7e="hour-suffix"> óra</span></div>
                </ng-container>
            </div>

            <!-- TA -->
            <app-section-title *ngIf="showReadonlyFields || isMoreThanDeptHead || ALLOW_CREW_EDIT_TA_FEE" 
                [title]="t7eSectionTa"></app-section-title>
            <div class="fees fees-ta" *ngIf="showReadonlyFields || isMoreThanDeptHead || ALLOW_CREW_EDIT_TA_FEE" [class.crew]="!isMoreThanDeptHead">
                <div class="grid-header" t7e="value-in-start-form">Start formban megadott érték</div>
                <div class="grid-header grid-header-history" t7e="history" t7e-title>History</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="inherited">Örökölt</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="dept-default">Department default</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="prod-default">Production default</div>

                <mat-form-field class="form-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_shortresthours')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                    <mat-label t7e="ta-short-len-label">Rövid pihenőidő hossza</mat-label>
                    <input matNativeControl #shortTaHours formControlName="f_saved_shortresthours" type="number" min="0" max="16"
                        step="1">
                    <span matSuffix t7e="hour-suffix"> óra</span>
                    <mat-hint><span t7e="only-if-not">Csak ha nem</span> {{ prodDefaults?.shortresthours }}<span t7e="hour-suffix"> óra</span></mat-hint>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_saved_shortresthours')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error *ngIf="form?.hasError('min', 'f_saved_shortresthours') || form?.hasError('max', 'f_saved_shortresthours')">
                        {{ getMinMaxErrorMsg(shortTaHours) }}
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_shortresthours', true)" type="button" *ngIf="isMoreThanDeptHead"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead">
                    <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_shortresthours')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                    {{ (contract$ | async)?.f_shortresthours }}<span t7e="hour-suffix"> óra</span>
                </div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead">{{prodDefaults?.shortresthours}}<span t7e="hour-suffix"> óra</span></div>

                <mat-form-field class="form-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_longresthours')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                    <mat-label t7e="ta-long-len-label">Hosszú pihenőidő hossza</mat-label>
                    <input matNativeControl #longTaHours formControlName="f_saved_longresthours" type="number" min="0" max="48"
                        step="1">
                    <span matSuffix t7e="hour-suffix"> óra</span>
                    <mat-hint><span t7e="only-if-not">Csak ha nem</span> {{ prodDefaults?.longresthours }}<span t7e="hour-suffix"> óra</span></mat-hint>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_saved_longresthours')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error *ngIf="form?.hasError('min', 'f_saved_longresthours') || form?.hasError('max', 'f_saved_longresthours')">
                        {{ getMinMaxErrorMsg(longTaHours) }}
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_longresthours', true)" type="button" *ngIf="isMoreThanDeptHead"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead">
                    <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_longresthours')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                    {{ (contract$ | async)?.f_longresthours }}<span t7e="hour-suffix"> óra</span>
                </div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead"></div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead">{{prodDefaults?.longresthours}}<span t7e="hour-suffix"> óra</span></div>

                <mat-form-field class="form-field" appearance="outline" *ngIf="ALLOW_CREW_EDIT_TA_FEE">
                    <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_tarate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                    <mat-label t7e="ta-rate-label">TA óradíj</mat-label>
                    <input matNativeControl #taRate formControlName="f_saved_tarate" type="number" min="0" step="10000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-hint *ngIf="isMoreThanDeptHead">
                        <span t7e="only-if-not">Csak ha nem</span> {{ (deptDefaults$ | async)?.f_tarate || prodDefaults?.tarate }}
                    </mat-hint>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_saved_tarate')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error *ngIf="form?.hasError('min', 'f_saved_tarate') || form?.hasError('max', 'f_saved_tarate')">
                        {{ getMinMaxErrorMsg(taRate) }}
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_tarate', true)" type="button" *ngIf="ALLOW_CREW_EDIT_TA_FEE"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead && ALLOW_CREW_EDIT_TA_FEE">
                    <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_tarate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                    {{ (contract$ | async)?.f_tarate }}&nbsp;{{ currency }}
                </div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead && ALLOW_CREW_EDIT_TA_FEE">{{(deptDefaults$ | async)?.f_tarate|| '-'}} {{ prodDefaultCurrency }}</div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead && ALLOW_CREW_EDIT_TA_FEE">{{prodDefaults?.tarate}} {{ prodDefaultCurrency }}</div>
            </div>
        </section>

        <!-- MEAL PENALTY -->
        <app-section-title [title]="'Meal Penalty'" *ngIf="isMealPenaltyInSf && isMoreThanDeptHead"></app-section-title>
        <section class="meal-penalty-field-wrapper" *ngIf="isMealPenaltyInSf && isMoreThanDeptHead">
            <div class="fees fees-meal-penalty" *ngIf="showReadonlyFields || isMoreThanDeptHead">
                <div class="grid-header" t7e="value-in-start-form">Start formban megadott érték</div>
                <div class="grid-header grid-header-history" t7e="history" t7e-title>History</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="inherited">Örökölt</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="dept-default">Department default</div>
                <div class="grid-header" *ngIf="isMoreThanDeptHead" t7e="prod-default">Production default</div>

                <mat-form-field class="form-field" appearance="outline">
                    <div class="will-be-effective-value" *ngIf="isMoreThanDeptHead && !isFieldEmpty('f_saved_mealPenaltyRate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></div>
                    <mat-label t7e="meal-penalty-label">Meal Penalty egy teljes órára</mat-label>
                    <input matNativeControl #mealPenaltyRate formControlName="f_saved_mealPenaltyRate" type="number" min="0" step="10000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-hint *ngIf="isMoreThanDeptHead">
                        <span t7e="only-if-not">Csak ha nem</span> {{ (deptDefaults$ | async)?.f_mealPenaltyRate || prodDefaults?.mealPenaltyRate }}
                    </mat-hint>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_saved_mealPenaltyRate')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error *ngIf="form?.hasError('min', 'f_saved_mealPenaltyRate') || form?.hasError('max', 'f_saved_mealPenaltyRate')">
                        {{ getMinMaxErrorMsg(mealPenaltyRate) }}
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'f_mealPenaltyRate', true)" type="button" class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <div class="effective-value" *ngIf="isMoreThanDeptHead">
                    <span class="will-be-effective-value" *ngIf="isFieldEmpty('f_saved_mealPenaltyRate')" [title]="t7eWillBeEffectiveTitle"><mat-icon>check</mat-icon></span>
                    {{ (contract$ | async)?.f_mealPenaltyRate }}&nbsp;{{ currency }}
                </div>
                <div class="dept-default" *ngIf="isMoreThanDeptHead">{{(deptDefaults$ | async)?.f_mealPenaltyRate|| '-'}} {{ prodDefaultCurrency }}</div>
                <div class="prod-default" *ngIf="isMoreThanDeptHead">{{prodDefaults?.mealPenaltyRate}} {{ prodDefaultCurrency }}</div>
            </div>
        </section>

        <!-- DAILY AND MONTHLY RENTALS -->
        <app-section-title [title]="t7eSectionRentalsMonthly" *ngIf="isMoreThanCrew || !hasInvoiceContact"></app-section-title>
        <section class="rental-field-wrapper" *ngIf="isMoreThanCrew ||!hasInvoiceContact">
            <!-- monthly rental 1 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="monthly-rental-name-label">Havi bérleti díj tárgya</span> #1</mat-label>
                <input matNativeControl formControlName="f_monthlybasedrentalrate1name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="monthly-rental-rate-label">Havi bérleti díj</span> #1</mat-label>
                    <input matNativeControl #monthlyRental1 formControlName="f_monthlybasedrentalrate1" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_monthlybasedrentalrate1')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_monthlybasedrentalrate1') || form?.hasError('max', 'f_monthlybasedrentalrate1')">
                        {{ getMinMaxErrorMsg(monthlyRental1) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #1</mat-label>
                    <input matNativeControl #monthlyRental1Code formControlName="f_monthlybasedrentalrate1code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_monthlybasedrental1type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>
        
            <!-- monthly rental 2 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="monthly-rental-name-label">Havi bérleti díj tárgya</span> #2</mat-label>
                <input matNativeControl formControlName="f_monthlybasedrentalrate2name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="monthly-rental-rate-label">Havi bérleti díj</span> #2</mat-label>
                    <input matNativeControl #monthlyRental2 formControlName="f_monthlybasedrentalrate2" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_monthlybasedrentalrate2')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_monthlybasedrentalrate2') || form?.hasError('max', 'f_monthlybasedrentalrate2')">
                        {{ getMinMaxErrorMsg(monthlyRental2) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #2</mat-label>
                    <input matNativeControl #monthlyRental2Code formControlName="f_monthlybasedrentalrate2code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_monthlybasedrental2type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>
        
            <!-- monthly rental 3 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="monthly-rental-name-label">Havi bérleti díj tárgya</span> #3</mat-label>
                <input matNativeControl formControlName="f_monthlybasedrentalrate3name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="monthly-rental-rate-label">Havi bérleti díj</span> #3</mat-label>
                    <input matNativeControl #monthlyRental3 formControlName="f_monthlybasedrentalrate3" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_monthlybasedrentalrate3')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_monthlybasedrentalrate3') || form?.hasError('max', 'f_monthlybasedrentalrate3')">
                        {{ getMinMaxErrorMsg(monthlyRental3) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #3</mat-label>
                    <input matNativeControl #monthlyRental3Code formControlName="f_monthlybasedrentalrate3code">
                </mat-form-field>
            </div>
                    <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_monthlybasedrental3type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>

        </section>
        
        <app-section-title [title]="t7eSectionRentalsDaily" *ngIf="isMoreThanCrew || !hasInvoiceContact"></app-section-title>
        <section class="rental-field-wrapper" *ngIf="isMoreThanCrew || !hasInvoiceContact">
        
            <!-- daily rental 1 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="daily-rental-name-label">Napi bérleti díj tárgya</span> #1</mat-label>
                <input matNativeControl formControlName="f_dailybasedrentalrate1name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="daily-rental-rate-label">Napi bérleti díj</span> #1</mat-label>
                    <input matNativeControl #dailyRental1 formControlName="f_dailybasedrentalrate1" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_dailybasedrentalrate1')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_dailybasedrentalrate1') || form?.hasError('max', 'f_dailybasedrentalrate1')">
                        {{ getMinMaxErrorMsg(dailyRental1) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #1</mat-label>
                    <input matNativeControl #dailyRental1Code formControlName="f_dailybasedrentalrate1code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_dailybasedrental1type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>
        
            <!-- daily rental 2 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="daily-rental-name-label">Napi bérleti díj tárgya</span> #2</mat-label>
                <input matNativeControl formControlName="f_dailybasedrentalrate2name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="daily-rental-rate-label">Napi bérleti díj</span> #2</mat-label>
                    <input matNativeControl #dailyRental2 formControlName="f_dailybasedrentalrate2" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_dailybasedrentalrate2')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_dailybasedrentalrate2') || form?.hasError('max', 'f_dailybasedrentalrate2')">
                        {{ getMinMaxErrorMsg(dailyRental2) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #2</mat-label>
                    <input matNativeControl #dailyRental2Code formControlName="f_dailybasedrentalrate2code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_dailybasedrental2type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>
        
            <!-- daily rental 3 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="daily-rental-name-label">Napi bérleti díj tárgya</span> #3</mat-label>
                <input matNativeControl formControlName="f_dailybasedrentalrate3name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="daily-rental-rate-label">Napi bérleti díj</span> #3</mat-label>
                    <input matNativeControl #dailyRental3 formControlName="f_dailybasedrentalrate3" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_dailybasedrentalrate3')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_dailybasedrentalrate3') || form?.hasError('max', 'f_dailybasedrentalrate3')">
                        {{ getMinMaxErrorMsg(dailyRental3) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #3</mat-label>
                    <input matNativeControl #dailyRental3Code formControlName="f_dailybasedrentalrate3code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_dailybasedrental3type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>

            <!-- daily rental 4 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="daily-rental-name-label">Napi bérleti díj tárgya</span> #4</mat-label>
                <input matNativeControl formControlName="f_dailybasedrentalrate4name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="daily-rental-rate-label">Napi bérleti díj</span> #4</mat-label>
                    <input matNativeControl #dailyRental4 formControlName="f_dailybasedrentalrate4" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_dailybasedrentalrate4')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_dailybasedrentalrate4') || form?.hasError('max', 'f_dailybasedrentalrate4')">
                        {{ getMinMaxErrorMsg(dailyRental4) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #4</mat-label>
                    <input matNativeControl #dailyRental4Code formControlName="f_dailybasedrentalrate4code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_dailybasedrental4type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>
            
            <!-- daily rental 5 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="daily-rental-name-label">Napi bérleti díj tárgya</span> #5</mat-label>
                <input matNativeControl formControlName="f_dailybasedrentalrate5name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="daily-rental-rate-label">Napi bérleti díj</span> #5</mat-label>
                    <input matNativeControl #dailyRental5 formControlName="f_dailybasedrentalrate5" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_dailybasedrentalrate5')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_dailybasedrentalrate5') || form?.hasError('max', 'f_dailybasedrentalrate5')">
                        {{ getMinMaxErrorMsg(dailyRental5) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #5</mat-label>
                    <input matNativeControl #dailyRental5Code formControlName="f_dailybasedrentalrate5code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_dailybasedrental5type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>

            <!-- daily rental 6 -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label><span t7e="daily-rental-name-label">Napi bérleti díj tárgya</span> #6</mat-label>
                <input matNativeControl formControlName="f_dailybasedrentalrate6name">
            </mat-form-field>
            <div class="form-field fee-and-code-fields">
                <mat-form-field appearance="outline">
                    <mat-label><span t7e="daily-rental-rate-label">Napi bérleti díj</span> #6</mat-label>
                    <input matNativeControl #dailyRental6 formControlName="f_dailybasedrentalrate6" type="number" min="0"
                        step="1000">
                    <span matSuffix>&nbsp;{{ currency }}</span>
                    <mat-error *ngIf="form?.hasError('pattern', 'f_dailybasedrentalrate6')" t7e="invalid-number-error">
                        Érvénytelen szám
                    </mat-error>
                    <mat-error
                        *ngIf="form?.hasError('min', 'f_dailybasedrentalrate6') || form?.hasError('max', 'f_dailybasedrentalrate6')">
                        {{ getMinMaxErrorMsg(dailyRental6) }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="rental-code-field" *ngIf="isMoreThanDeptHead" appearance="outline">
                    <mat-label><span t7e="rental-code-label">Kód: Bérleti díj</span> #6</mat-label>
                    <input matNativeControl #dailyRental6Code formControlName="f_dailybasedrentalrate6code">
                </mat-form-field>
            </div>
            <div class="rentalratetype-wrapper mb-2">
                <mat-checkbox formControlName="f_dailybasedrental6type">
                    {{ 'combine-rentals' | t7e: 'Összevonás számlaképben' }}
                </mat-checkbox>
            </div>

        </section>
        </ng-container>

        <!-- KÖNYVELÉSI KÓDOK -->
        <app-section-title [title]="t7eSectionCodes" *ngIf="isMoreThanDeptHead"></app-section-title>
        <section class="bookkeeping-codes" *ngIf="isMoreThanDeptHead">
            <mat-form-field class="form-field" appearance="outline">
                <mat-label t7e="code-dailyfee-label">Kód: Napidíj</mat-label>
                <input matNativeControl formControlName="code_salary">
                <mat-hint t7e="code-dailyfee-hint">A napidíj könyvelési kódja</mat-hint>
            </mat-form-field>

            <mat-form-field class="form-field" appearance="outline" *ngIf="showOtCodes$ | async; else noOtCodeInfo">
                <mat-label t7e="code-otta-label">Kód: OT & TA</mat-label>
                <input matNativeControl formControlName="code_otta">
                <mat-hint t7e="code-otta-hint">Az OT és TA könyvelési kódja</mat-hint>
            </mat-form-field>
            <ng-template #noOtCodeInfo>
                <div class="code-in-prod-defaults">
                    <a t7e="code-otta-find-in-prod-defaults" [routerLink]="['/admin/prod-defaults']"></a>
                </div>
            </ng-template>
            
            <mat-form-field class="form-field" appearance="outline" *ngIf="showGasCode$ | async; else noGasCodeInfo">
                <mat-label t7e="code-km-label">Kód: Üzemanyag</mat-label>
                <input matNativeControl formControlName="code_gas">
                <mat-hint t7e="code-km-hint">Az Üzemanyag könyvelési kódja</mat-hint>
            </mat-form-field>
            <ng-template #noGasCodeInfo>
                <div class="code-in-prod-defaults">
                    <a t7e="code-km-find-in-prod-defaults" [routerLink]="['/admin/prod-defaults']"></a>
                </div>
            </ng-template>
            
            <mat-form-field class="form-field" appearance="outline" *ngIf="showParkingCode$ | async; else noParkingCode">
                <mat-label t7e="code-parking-label">Kód: Parkolás</mat-label>
                <input matNativeControl formControlName="code_park">
                <mat-hint t7e="code-parking-hint">A parkolás könyvelési kódja</mat-hint>
            </mat-form-field>
            <ng-template #noParkingCode>
                <div class="code-in-prod-defaults">
                    <a t7e="code-parking-find-in-prod-defaults" [routerLink]="['/admin/prod-defaults']"></a>
                </div>
            </ng-template>

            <mat-form-field class="form-field" appearance="outline" *ngIf="showVignetteCode$ | async; else noVignetteCode">
                <mat-label t7e="code-vignette-label">Kód: Matrica</mat-label>
                <input matNativeControl formControlName="code_vignette">
                <mat-hint t7e="code-vignette-hint">A Matrica könyvelési kódja</mat-hint>
            </mat-form-field>
            <ng-template #noVignetteCode>
                <div class="code-in-prod-defaults">
                    <a t7e="code-vignette-find-in-prod-defaults" [routerLink]="['/admin/prod-defaults']"></a>
                </div>
            </ng-template>

            <mat-form-field class="form-field" appearance="outline">
                <mat-label t7e="code-perdiem-label">Kód: Per Diem</mat-label>
                <input matNativeControl formControlName="code_perdiem">
                <mat-hint t7e="code-perdiem-hint">A Per Diem könyvelési kódja</mat-hint>
            </mat-form-field>
            <mat-form-field class="form-field" appearance="outline">
                <mat-label t7e="code-otherallowance-label">Kód: Egyéb</mat-label>
                <input matNativeControl formControlName="code_other">
                <mat-hint t7e="code-otherallowance-hint">Az egyéb szerződésen felüli teljesítések könyvelési kódja</mat-hint>
            </mat-form-field>
        </section>

        <ng-container *ngIf="isMoreThanCrew || ALLOW_CREW_MEMBER_EDIT_CONTRACT_DATES">
        <section class="startend">
            <app-section-title [title]="t7eSectionStartDate"></app-section-title>
            <mat-form-field class="form-field" appearance="outline">
                <mat-label t7e="startdate-label">Várható kezdés dátuma</mat-label>
                <input matInput [matDatepicker]="startdate" formControlName="startdate" [max]="contractEndDate">
                <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
                <mat-datepicker #startdate></mat-datepicker>
                <mat-hint t7e="startdate-hint">Ez előtt a dátum előtt nem küldhetsz be timesheetet</mat-hint>
            </mat-form-field>

            <mat-form-field class="form-field" appearance="outline" *ngIf="ALLLOW_CONTRACT_END_DATE">
                <mat-label t7e="enddate-label">Várható befejezés dátuma</mat-label>
                <input matInput [matDatepicker]="enddate" formControlName="enddate" [min]="contractStartDate">
                <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
                <mat-datepicker #enddate></mat-datepicker>
                <mat-hint t7e="enddate-hint">Ez után a dátum után nem küldhetsz be timesheetet</mat-hint>
            </mat-form-field>
        </section>
        </ng-container>

        <section class="user-flexfields">
            <app-section-title [title]="t7eSectionOther"></app-section-title>
            <app-flex-field-group *ngFor="let group of questionGroups$ | async" [fullFormGroup]="flexFieldsFG"
                [questionGroup]="group" [tableid]="contractFlexTable" [showReadonlyFields]="showReadonlyFields"
                [formFieldStyles]="formFieldStyles" [formGroupStyles]="formGroupStyles" class="flex-form-fields"
                [isEditable]="canEditForm" [validate$]="validateFlexFields" 
                [uploadFolderName]="uploadFolderName" [uploadFolderId]="uploadFolderId"
                (fileUploadSuccess)="onFileUploadSuccess($event)" (fileUploadError)="onFileUploadError($event)">
            </app-flex-field-group>
        </section>

        <section class="save-buttons">
            <app-save-button color="primary" [disabled]="canSave().disabled"
                [saveError$]="saveError$" [errorText]="t7eSaveError" [showErrMsg]="true"
                [title]="canSave().title">
            </app-save-button>

            <app-save-button [disabled]="canSaveAsNew().disabled" *ngIf="contract?.conid"
                [buttonText]="btnTextSaveAsNew" [color]="null" [showDoneIcon]="false"
                [saveError$]="saveError$" [errorText]="t7eSaveError" [showErrMsg]="true"
                [title]="canSaveAsNew().title" (btnClick)="saveAsNew()">
            </app-save-button>

            <!-- SEND TO DEPT HEAD -->
            <app-save-button color="primary" *ngIf="isAtCrewMember && hasDeptHead" 
                [disabled]="canChangeStatus(ContractStatus.deptHead).disabled"
                [saveError$]="saveStatusError$" [buttonText]="t7eSendToDeptHead" (btnClick)="saveStatusChange(ContractStatus.deptHead)"
                [errorText]="t7eSendError" [showErrMsg]="true" [showDoneIcon]="false"
                [title]="canChangeStatus(ContractStatus.deptHead).title">
            </app-save-button>

            <!-- SEND TO PROD MGR -->
            <app-save-button color="primary" *ngIf="!canChangeStatus(ContractStatus.prodMgr).disabled && !isAtDeptHead"
                [saveError$]="saveStatusError$" [buttonText]="btnTextSendToProdMgr" (btnClick)="saveStatusChange(ContractStatus.prodMgr)"
                [errorText]="t7eSendError" [showErrMsg]="true" [showDoneIcon]="false"
                [title]="canChangeStatus(ContractStatus.prodMgr).title">
            </app-save-button>
            <app-save-button color="primary" *ngIf="!canChangeStatus(ContractStatus.crewMember).disabled"
                [saveError$]="saveStatusError$" [buttonText]="t7eSendBackToCrew" (btnClick)="sendBackToCrewClicked()"
                [errorText]="t7eSendBackError" [showErrMsg]="true" [showDoneIcon]="false"
                [title]="canChangeStatus(ContractStatus.crewMember).title">
            </app-save-button>

            <!-- DEPT HEAD APPROVE -->
            <app-save-button color="primary" [disabled]="canChangeStatus(ContractStatus.prodMgr).disabled"
                *ngIf="isDeptHead" [saveError$]="saveStatusError$" [buttonText]="t7eDeptApprove"
                (btnClick)="saveStatusChange(ContractStatus.prodMgr)" [errorText]="t7eApproveError" [showErrMsg]="true"
                [showDoneIcon]="false" [title]="canChangeStatus(ContractStatus.prodMgr).title">
            </app-save-button>

            <!-- PROD MGR APPROVE -->
            <app-save-button color="primary" [disabled]="canChangeStatus(ContractStatus.prodMgrApproved).disabled"
                *ngIf="isMoreThanDeptHead"
                [saveError$]="saveStatusError$" [buttonText]="t7eApprove" (btnClick)="saveStatusChange(ContractStatus.prodMgrApproved)"
                [errorText]="t7eApproveError" [showErrMsg]="true" [showDoneIcon]="false"
                [title]="canChangeStatus(ContractStatus.prodMgrApproved).title">
            </app-save-button>

            <!-- DELETE -->
            <app-save-button [color]="null" [buttonText]="'remove' | t7e"
                *ngIf="!canChangeStatus(ContractStatus.deleted).disabled" [saveError$]="saveStatusError$"
                (btnClick)="saveStatusChange(ContractStatus.deleted)" [errorText]="t7eDeleteError" [showErrMsg]="true"
                [showDoneIcon]="false" [title]="canChangeStatus(ContractStatus.deleted).title">
            </app-save-button>

            <!-- FINANCE -->
            <app-save-button color="primary" [disabled]="canChangeStatus(ContractStatus.financeApproved).disabled"
                *ngIf="isFinance && (isFinanceApprovalNeeded$ | async)" [saveError$]="saveStatusError$" [buttonText]="t7eFinanceApprove"
                (btnClick)="saveStatusChange(ContractStatus.financeApproved)" [errorText]="t7eFinanceApproveError" [showErrMsg]="true"
                [showDoneIcon]="false" [title]="canChangeStatus(ContractStatus.financeApproved).title">
            </app-save-button>
            <app-save-button color="primary" [disabled]="canChangeStatus(ContractStatus.readyToSign).disabled"
                *ngIf="isMoreThanDeptHead"
                [saveError$]="saveStatusError$" [buttonText]="t7eReadyToSign" (btnClick)="saveStatusChange(ContractStatus.readyToSign)"
                [errorText]="t7eSaveError" [showErrMsg]="true" [showDoneIcon]="false"
                [title]="canChangeStatus(ContractStatus.readyToSign).title">
            </app-save-button>
            <app-save-button color="primary" [disabled]="canChangeStatus(ContractStatus.signed).disabled"
                *ngIf="isMoreThanDeptHead"
                [saveError$]="saveStatusError$" [buttonText]="t7eSigned" (btnClick)="saveStatusChange(ContractStatus.signed)"
                [errorText]="t7eSaveError" [showErrMsg]="true" [showDoneIcon]="false"
                [title]="canChangeStatus(ContractStatus.signed).title">
            </app-save-button>
            <button mat-raised-button color="secondary" (click)="navToNewTs()" *ngIf="isSent" type="button">
                <span t7e="goto-my-ts">Tovább a timesheetekhez</span>
                <mat-icon>forward</mat-icon>
            </button>
        </section>

    </form>
</div>
<kendo-popover #popHistory>
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-history title" t7e="history">
            Előzmények
        </div>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate>
        <div class="pop-history body">
            <app-history [tableid]="history.tableid" [id]="history.id" [colname]="history.colname"
                [propcode]="history.propcode" *ngIf="history; else noHistory"></app-history>
            <ng-template #noHistory>
                <div class="history-error" t7e="history-error">Előzmény hiba</div>
            </ng-template>
        </div>
    </ng-template>
</kendo-popover>

<app-save-comment *ngIf="showSaveComment" [questionText]="showSaveComment.title"
    [isCommentOptional]="showSaveComment.isCommentOptional" (saveClicked)="saveCommentReceived($event)"
    (cancelClicked)="showSaveComment = null">
</app-save-comment>